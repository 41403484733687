import { useEffect, useMemo, useState } from "react";
import {
  Feature,
  ZenidWebSdk,
  zenidModels,
  Events,
  SupportedLanguages,
  AcceptableInput,
  PageCode,
  DocumentCode,
  SupportedLanguage,
  DocumentData,
  SelfieData,
  Feedback,
  Visualizer,
  PossibleDocument,
  Model,
} from "zenid";
import { pipe, constant, flow } from "fp-ts/function";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import {
  taskEither,
  task,
  either,
  option,
  array,
  record,
  eq,
  nonEmptyArray,
} from "fp-ts";
import { TaskEither } from "fp-ts/TaskEither";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { Either } from "fp-ts/Either";
import { useEvent } from "react-use";
import { Option } from "fp-ts/Option";
import { useAppContext } from "../../useAppContext";
import {
  APIParameters,
  DocumentIdentificationFlow,
  foldTenant,
  Tenant,
} from "../../globalDomain";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import * as t from "io-ts";
import { failure } from "io-ts/lib/PathReporter";
import { LocalizedString } from "design-system";
import { useFormatMessage } from "../../intl";
import { useIsRemoteChannel } from "../../useChannel";

const isFeedback = (u: number): u is Feedback =>
  pipe(
    Feedback,
    record.some(x => x === u)
  );

const FeedbackCodec = new t.Type(
  "Feedback",
  (u): u is Feedback => t.number.is(u) && isFeedback(u),
  (value, context) =>
    pipe(
      t.number.validate(value, context),
      either.chain(v =>
        isFeedback(v) ? t.success(v) : t.failure(value, context)
      )
    ),
  t.number.encode
);

const FeedbackEvent = t.type({
  feedback: FeedbackCodec,
});

export type ZenidError =
  | "InitializationFailed"
  | "InvalidToken"
  | "LoadFeatureFailed"
  | "CameraTestFailed"
  | "StartCameraFailed";

export const getDocumentCodes = (
  tenant: Tenant,
  isRemote: boolean,
  isPersonalProfile: boolean,
  documentIdentificationFlow: DocumentIdentificationFlow
) => {
  return foldTenant(
    tenant,
    () => {
      switch (documentIdentificationFlow) {
        case "PrimaryAndLivenessCheck":
        case "Primary":
          return isRemote && !isPersonalProfile
            ? [...idCardCodesSK]
            : [...idCardCodesSK, ...passportCodesCZ];
        case "Secondary":
          return !isPersonalProfile
            ? []
            : [
                ...passportCodesSK,
                ...drivingLicenseCodesSK,
                ...drivingLicenseCodesCZ,
              ];
        case "PrimaryAndSecondary":
          return [
            ...idCardCodesSK,
            ...passportCodesCZ,
            ...passportCodesSK,
            ...drivingLicenseCodesSK,
            ...drivingLicenseCodesCZ,
          ];
      }
    },
    () => {
      switch (documentIdentificationFlow) {
        case "PrimaryAndLivenessCheck":
        case "Primary":
          return !isPersonalProfile && isRemote
            ? [...idCardCodesCZ]
            : [...idCardCodesCZ, ...passportCodesSK];
        case "Secondary":
          return isPersonalProfile
            ? [
                ...passportCodesCZ,
                ...drivingLicenseCodesCZ,
                ...drivingLicenseCodesSK,
              ]
            : isRemote
            ? [...passportCodesCZ, ...drivingLicenseCodesCZ]
            : [];
        case "PrimaryAndSecondary":
          return [
            ...idCardCodesCZ,
            ...passportCodesSK,
            ...passportCodesCZ,
            ...drivingLicenseCodesCZ,
            ...drivingLicenseCodesSK,
          ];
      }
    }
  );
};

const idCardCodesCZ: DocumentCode[] = [DocumentCode.IDC2];
const passportCodesCZ: DocumentCode[] = [DocumentCode.PAS];
const drivingLicenseCodesCZ: DocumentCode[] = [DocumentCode.DRV];
// const residencePermitCodesCZ: DocumentCode[] = [DocumentCode.CZ_RES_2006_T, DocumentCode.CZ_RES_2006_07, DocumentCode.CZ_RES_2011_14];
// ^^ Could be useful in future ^^
const passportCodesSK: DocumentCode[] = [DocumentCode.SK_PAS_2008_14];
const drivingLicenseCodesSK: DocumentCode[] = [
  DocumentCode.SK_DRV_2004_08_09,
  DocumentCode.SK_DRV_2013,
  DocumentCode.SK_DRV_2015,
];
const idCardCodesSK: DocumentCode[] = [
  DocumentCode.SK_IDC_2008plus,
  DocumentCode.SK_IDC_2022,
  DocumentCode.SK_IDC_2024,
];

const getAllTenantModels = (
  tenant: Tenant,
  isRemote: boolean,
  isPersonalProfile: boolean
) =>
  zenidModels.filter(model => {
    return getDocumentCodes(
      tenant,
      isRemote,
      isPersonalProfile,
      "PrimaryAndSecondary"
    ).includes(model.documentCode);
  });

const validateToken = (token: string): Either<ZenidError, NonEmptyString> =>
  pipe(
    token,
    NonEmptyString.decode,
    either.mapLeft<unknown, ZenidError>(constant("InvalidToken"))
  );

const initialize = (
  exchangeTokenCommand: ReaderTaskEither<
    { token: NonEmptyString },
    unknown,
    { token: NonEmptyString }
  >
): Either<ZenidError, void> => {
  const initializationCallback = (token: string): Promise<string> =>
    pipe(
      token,
      validateToken,
      taskEither.fromEither,
      taskEither.chain(token => exchangeTokenCommand({ token })),
      taskEither.fold(
        e => constant(Promise.reject(e)),
        ({ token: exchangedToken }) => task.of(exchangedToken)
      )
    )();

  return either.tryCatch(
    () => ZenidWebSdk.initialize(initializationCallback),
    constant("InitializationFailed")
  );
};

const visualizer = new Visualizer({
  drawText: false,
});

const loadFeature = (
  tenant: Tenant,
  isRemote: boolean,
  isPersonalProfile: boolean,
  documentBlurAcceptableScore: number,
  specularAcceptableScore: number,
  timeToBlurMaxTolerance: number,
  enableAimingCircle?: boolean,
  drawOutline?: boolean,
  models?: Model[]
): ((feature: Feature) => TaskEither<ZenidError, void>) => {
  return (feature: Feature) =>
    taskEither.tryCatch(() => {
      const settings = {
        feature,
        enabledModels:
          feature !== "selfie" && feature !== "selfieVideo"
            ? models
              ? models
              : getAllTenantModels(tenant, isRemote, isPersonalProfile)
            : undefined,
        visualizer,
        enableAimingCircle: enableAimingCircle,
        documentBlurAcceptableScore:
          feature !== "selfie" && feature !== "selfieVideo"
            ? documentBlurAcceptableScore
            : undefined,
        specularAcceptableScore:
          feature !== "selfie" && feature !== "selfieVideo"
            ? specularAcceptableScore
            : undefined,
        timeToBlurMaxTolerance:
          feature !== "selfie" && feature !== "selfieVideo"
            ? timeToBlurMaxTolerance
            : undefined,
      };
      //"drawOutline" is bugged in zenId as it checks whether it is present instead of undefined
      return ZenidWebSdk.load(
        feature === "document"
          ? { ...settings, drawOutline: drawOutline }
          : settings
      );
    }, constant("LoadFeatureFailed" as ZenidError));
};

const loadFeatures: (
  tenant: Tenant,
  isRemote: boolean,
  isPersonalProfile: boolean,
  features: NonEmptyArray<Feature>,
  documentBlurAcceptableScore: number,
  specularAcceptableScore: number,
  timeToBlurMaxTolerance: number,
  enableAimingCircle?: boolean,
  drawOutline?: boolean,
  models?: Model[]
) => TaskEither<ZenidError, void> = (
  tenant,
  isRemote,
  isPersonalProfile,
  features,
  documentBlurAcceptableScore,
  specularAcceptableScore,
  timeToBlurMaxTolerance,
  enableAimingCircle?: boolean,
  drawOutline?: boolean,
  models?: Model[]
) =>
  pipe(
    features,
    array.traverse(taskEither.taskEither)(
      loadFeature(
        tenant,
        isRemote,
        isPersonalProfile,
        documentBlurAcceptableScore,
        specularAcceptableScore,
        timeToBlurMaxTolerance,
        enableAimingCircle,
        drawOutline,
        models
      )
    ),
    taskEither.map(() => {})
  );

const testCamera: TaskEither<ZenidError, void> = taskEither.tryCatch(
  () => ZenidWebSdk.testCamera(),
  constant("CameraTestFailed")
);

const initializeZenid = (
  tenant: Tenant,
  isRemote: boolean,
  isPersonalProfile: boolean,
  features: NonEmptyArray<Feature>,
  exchangeTokenCommand: ReaderTaskEither<
    { token: NonEmptyString },
    unknown,
    { token: NonEmptyString }
  >,
  documentBlurAcceptableScore: number,
  specularAcceptableScore: number,
  timeToBlurMaxTolerance: number,
  enableAimingCircle?: boolean,
  drawOutline?: boolean,
  models?: Model[]
): TaskEither<ZenidError, void> =>
  pipe(
    initialize(exchangeTokenCommand),
    taskEither.fromEither,
    taskEither.chain(() =>
      loadFeatures(
        tenant,
        isRemote,
        isPersonalProfile,
        features,
        documentBlurAcceptableScore,
        specularAcceptableScore,
        timeToBlurMaxTolerance,
        enableAimingCircle,
        drawOutline,
        models
      )
    ),
    taskEither.chain(() => testCamera)
  );

export const useZenid = (
  isPersonalProfile: boolean,
  features: NonEmptyArray<Feature>,
  exchangeTokenCommand: ReaderTaskEither<
    { token: NonEmptyString },
    unknown,
    { token: NonEmptyString }
  >,
  onLoaded: (feature: Feature[]) => unknown,
  autoInitialize: boolean,
  models?: Model[]
): {
  initializeZenid: TaskEither<ZenidError, unknown>;
  loadedFeatures: Feature[];
} => {
  const [initialized, setInitialized] = useState(false);
  const [loadedFeatures, _setLoadedFeatures] = useState<Feature[]>(
    (window as any).zenIdLoadedFeatures !== undefined
      ? (window as any).zenIdLoadedFeatures
      : []
  );

  const setLoadedFeatures = (features: Feature[]) => {
    (window as any).zenIdLoadedFeatures = features;
    _setLoadedFeatures(features);
  };

  const {
    config: {
      environment,
      documentBlurAcceptableScore,
      specularAcceptableScore,
      timeToBlurMaxTolerance,
      useNewMobileIdUploadUx,
    },
    apiParameters: { tenant },
  } = useAppContext();

  const isRemote = useIsRemoteChannel();

  useMemo(() => {
    ZenidWebSdk.addLogListener(
      function ({ level, message }) {
        switch (level) {
          case "error":
            console.error(message);
            break;
          case "warn":
            console.warn(message);
            break;
          case "info":
            console.info(message);
            break;
          case "debug":
            console.debug(message);
            break;
        }
      },
      { level: environment !== "prod" ? "debug" : "warn" }
    );

    return ZenidWebSdk.clearLogListeners;
  }, []);

  useZenidEvent("loaded", (event: any) => {
    if (event && event.detail && features.includes(event.detail.feature)) {
      const newLoadedFeatures = [...loadedFeatures, event.detail.feature];
      setLoadedFeatures(newLoadedFeatures);
      if (newLoadedFeatures.length == features.length) {
        onLoaded(newLoadedFeatures);
      }
    }
  });

  const initializeZenidFn: TaskEither<ZenidError, unknown> = pipe(
    features,
    array.difference<Feature>(eq.eqString)(loadedFeatures),
    nonEmptyArray.fromArray,
    option.fold(
      () => taskEither.fromIO(() => onLoaded(features)),
      newFeatures =>
        initializeZenid(
          tenant,
          isRemote,
          isPersonalProfile,
          newFeatures,
          exchangeTokenCommand,
          documentBlurAcceptableScore,
          specularAcceptableScore,
          timeToBlurMaxTolerance,
          useNewMobileIdUploadUx ? false : undefined,
          useNewMobileIdUploadUx ? false : undefined,
          models
        )
    )
  );

  if (autoInitialize && !initialized) {
    initializeZenidFn();
    setInitialized(true);
  }

  return {
    initializeZenid: initializeZenidFn,
    loadedFeatures: loadedFeatures,
  };
};

const getLanguage = (
  language: APIParameters["language"]
): SupportedLanguage => {
  switch (language) {
    case "cs":
      return SupportedLanguages.Czech;
    case "en":
      return SupportedLanguages.English;
    case "sk":
      return SupportedLanguages.English;
  }
};

export type CameraError = "unavailable" | "disabled" | "generic";

type UseCameraSettings = {
  cameraPreviewContainer: string;
  onError: (cameraError: CameraError) => unknown;
} & (
  | {
      feature: Extract<Feature, "document" | "documentVideo">;
      acceptableInput: Option<AcceptableInput>;
    }
  | {
      feature: Extract<Feature, "selfie">;
      acceptableInput?: never;
    }
  | {
      feature: Extract<Feature, "selfieVideo">;
      acceptableInput?: never;
    }
);

const ZenIDCameraError = t.type(
  {
    name: t.keyof(
      {
        CameraNotAllowedException: true,
        CameraNotFoundException: true,
      },
      "CameraErrorName"
    ),
  },
  "CameraError"
);

export function useCamera(
  props: UseCameraSettings
): {
  startCamera: TaskEither<CameraError, unknown>;
  stopCamera: () => unknown;
  isStartingCamera: boolean;
  feedback: Option<Feedback>;
  feedbackText: Option<LocalizedString>;
} {
  const formatMessage = useFormatMessage();

  function isArrayOfDocumentAcceptable(documentCodes: DocumentCode[]) {
    return (acceptableInput: PossibleDocument[]): boolean =>
      pipe(
        acceptableInput,
        array.difference(
          eq.fromEquals(
            (a: PossibleDocument, b: PossibleDocument) =>
              a.DocumentCode === b.DocumentCode
          )
        )(
          pipe(
            documentCodes,
            array.map<DocumentCode, PossibleDocument>(dc => ({
              DocumentCode: dc,
            }))
          )
        )
      ).length === 0;
  }

  function formatFeedback(feedback: Feedback) {
    switch (feedback) {
      case Feedback.Ok:
        return formatMessage("Zenid.Feedback.ok");
      case Feedback.NoMatchFound:
        return pipe(
          option.fromNullable(props.acceptableInput),
          option.flatten,
          option.map(acceptableInput => {
            if (
              "documentCode" in acceptableInput &&
              "pageCode" in acceptableInput
            ) {
              return formatMessage("Zenid.Feedback.noMatchFound.backIdCard");
            } else if ("PossibleDocuments" in acceptableInput) {
              if (
                pipe(
                  acceptableInput.PossibleDocuments,
                  isArrayOfDocumentAcceptable(idCardCodesCZ)
                ) ||
                pipe(
                  acceptableInput.PossibleDocuments,
                  isArrayOfDocumentAcceptable(idCardCodesSK)
                )
              ) {
                return formatMessage("Zenid.Feedback.noMatchFound.frontIdCard");
              } else if (
                pipe(
                  acceptableInput.PossibleDocuments,
                  isArrayOfDocumentAcceptable([
                    ...idCardCodesSK,
                    ...passportCodesCZ,
                  ])
                ) ||
                pipe(
                  acceptableInput.PossibleDocuments,
                  isArrayOfDocumentAcceptable([
                    ...idCardCodesCZ,
                    ...passportCodesSK,
                  ])
                )
              ) {
                return formatMessage(
                  "Zenid.Feedback.noMatchFound.primaryFront"
                );
              } else if (
                pipe(
                  acceptableInput.PossibleDocuments,
                  isArrayOfDocumentAcceptable([
                    ...passportCodesSK,
                    ...drivingLicenseCodesSK,
                    ...drivingLicenseCodesCZ,
                  ])
                ) ||
                pipe(
                  acceptableInput.PossibleDocuments,
                  isArrayOfDocumentAcceptable([
                    ...passportCodesCZ,
                    ...drivingLicenseCodesCZ,
                    ...drivingLicenseCodesSK,
                  ])
                )
              ) {
                return formatMessage(
                  "Zenid.Feedback.noMatchFound.secondaryFront"
                );
              } else if (
                pipe(
                  acceptableInput.PossibleDocuments,
                  isArrayOfDocumentAcceptable([
                    ...drivingLicenseCodesSK,
                    ...drivingLicenseCodesCZ,
                  ])
                )
              ) {
                return formatMessage(
                  "Zenid.Feedback.noMatchFound.driverLicense"
                );
              }
            }
            return formatMessage("Zenid.Feedback.noMatchFound");
          }),
          option.getOrElse(
            constant(formatMessage("Zenid.Feedback.noMatchFound"))
          )
        );
      case Feedback.AlignCard:
        return formatMessage("Zenid.Feedback.alignCard");
      case Feedback.HoldSteady:
        return formatMessage("Zenid.Feedback.holdSteady");
      case Feedback.Blurry:
        return formatMessage("Zenid.Feedback.blurry");
      case Feedback.ReflectionPresent:
        return formatMessage("Zenid.Feedback.reflectionPresent");
      case Feedback.Dark:
        return formatMessage("Zenid.Feedback.dark");
      case Feedback.NoFaceFound:
        return formatMessage("Zenid.Feedback.noFaceFound");
      case Feedback.MoveElsewhere:
        return formatMessage("Zenid.Feedback.moveElsewhere");
      case Feedback.TiltLeftAndRight:
        return formatMessage("Zenid.Feedback.tiltLeftAndRight");
      case Feedback.TiltUpAndDown:
        return formatMessage("Zenid.Feedback.tiltUpAndDown");
      case Feedback.RotateClockwise:
        return formatMessage("Zenid.Feedback.rotateClockwise");
      case Feedback.RotateCounterClockwise:
        return formatMessage("Zenid.Feedback.rotateCounterClockwise");
      case Feedback.LookAtMe:
        return formatMessage("Zenid.Feedback.LookAtMe");
      case Feedback.TurnHead:
        return formatMessage("Zenid.Feedback.TurnHead");
      case Feedback.Smile:
        return formatMessage("Zenid.Feedback.Smile");
      case Feedback.ConfirmingFace:
        return formatMessage("Zenid.Feedback.ConfirmingFace");
      case Feedback.Center:
        return formatMessage("Zenid.Feedback.center");
      case Feedback.HoldStill:
        return formatMessage("Zenid.Feedback.holdStill");
      case Feedback.BadFaceAngle:
        return formatMessage("Zenid.Feedback.badFaceAngle");
      case Feedback.Barcode:
        return formatMessage("Zenid.Feedback.Barcode");
      default:
        return formatMessage("Zenid.Feedback.otherError");
    }
  }

  const [isStartingCamera, setIsStartingCamera] = useState(false);
  const [feedback, setFeedback] = useState<Option<Feedback>>(option.none);

  const stopCamera = () => {
    setIsStartingCamera(false);
    ZenidWebSdk.stopCamera();
  };

  const context = useAppContext();
  const settings = useMemo(
    () => ({
      language: getLanguage(context.apiParameters.language),
      videoTouchFromOutside: false,
      cutCenter: false,
      findGeneralPurposeCamera: true,
      cameraSelectionRules: "auto",
      acceptableInput:
        props.feature === "selfie" || props.feature === "selfieVideo"
          ? {}
          : pipe(props.acceptableInput, option.getOrElse(constant({}))),
    }),
    [props.feature, props.acceptableInput, context.apiParameters.language]
  );

  const startCamera = useMemo(() => {
    return pipe(
      taskEither.fromIO<CameraError, unknown>(() => setIsStartingCamera(true)),
      taskEither.chain(() =>
        taskEither.tryCatch(
          () =>
            ZenidWebSdk.startCamera(
              props.feature,
              `.${props.cameraPreviewContainer}`,
              settings
            ),
          flow(
            ZenIDCameraError.decode,
            either.map(
              (err): CameraError => {
                switch (err.name) {
                  case "CameraNotAllowedException":
                    return "disabled";
                  case "CameraNotFoundException":
                    return "unavailable";
                }
              }
            ),
            either.getOrElse(
              (e): CameraError => {
                console.log("ZenId error ", e);
                return "generic";
              }
            )
          )
        )
      ),
      taskEither.chain(() =>
        taskEither.fromIO(() => setIsStartingCamera(false))
      )
    );
  }, [props.feature, props.cameraPreviewContainer, settings]);

  useFeedbackEvent(feedback => {
    setFeedback(
      pipe(
        feedback,
        option.fromPredicate(feedback => feedback !== Feedback.Ok)
      )
    );
    if (isStartingCamera) {
      setIsStartingCamera(false);
    }
  });

  useEffect(() => {
    pipe(
      startCamera,
      taskEither.orElse(cameraError =>
        taskEither.leftIO(() => props.onError && props.onError(cameraError))
      )
    )();

    return stopCamera;
  }, [startCamera]);

  return {
    startCamera,
    stopCamera,
    isStartingCamera,
    feedback,
    feedbackText: pipe(feedback, option.map(formatFeedback)),
  };
}

export interface UseSnapshot<T extends DocumentData | SelfieData> {
  (
    snapshotPreviewContainer: string,
    onSnapshot: (
      snapshotData: T,
      hasOtherSide?: T extends DocumentData ? boolean : never
    ) => unknown
  ): void;
}

export const useDocumentSnapshot: UseSnapshot<DocumentData> = (
  snapshotPreviewContainer,
  onSnapshot
) => {
  const _onSnapshot = (event: any) => {
    const { pictureBlob, ...snapshot } = event.detail;
    const hasOtherSide: boolean = option.isSome(
      pipe(
        zenidModels,
        array.findFirst(
          model =>
            model.documentCode === snapshot.documentCode &&
            model.pageCode !== snapshot.pageCode
        )
      )
    );
    onSnapshot(
      {
        ...snapshot,
        blob: pictureBlob,
      },
      hasOtherSide
    );
    ZenidWebSdk.stopCamera();
    ZenidWebSdk.setSnapshotPreview(`.${snapshotPreviewContainer}`);
  };

  useZenidEvent("snapshot", _onSnapshot);
};

export const useSelfieSnapshot: UseSnapshot<SelfieData> = (
  snapshotPreviewContainer,
  onSnapshot
) => {
  const _onSnapshot = (event: any) => {
    const { pictureBlob, ...snapshot } = event.detail;
    onSnapshot({
      ...snapshot,
      blob: pictureBlob,
    });
    ZenidWebSdk.stopCamera();
    ZenidWebSdk.setSnapshotPreview(`.${snapshotPreviewContainer}`);
  };

  useZenidEvent("snapshot", _onSnapshot);
};

export const useVideo = (onVideo: (video: DocumentData) => unknown) => {
  const [isRecording, setIsRecording] = useState(false);
  const [videoChunks, setVideoChunks] = useState<Array<any>>([]);

  const finishRecording = () => {
    if (ZenidWebSdk.isRecording()) {
      ZenidWebSdk.finishRecording();
      ZenidWebSdk.stopCamera();
      setIsRecording(false);
    }
  };

  const onVideoChunk = (event: any) => {
    switch (event.detail.videoStatus) {
      case "started":
        setVideoChunks([]);
        return;
      case "running":
        setVideoChunks([...videoChunks, event.detail.videoStreamChunk]);
        return;
      case "finished":
        onVideo({
          ...event.detail,
          blob: new Blob(videoChunks, { type: "video/webm" }),
        });
        setVideoChunks([]);
        return;
    }
  };

  useFeedbackEvent(feedback => {
    if (
      !ZenidWebSdk.isRecording() &&
      feedback > Feedback.NoMatchFound &&
      !videoChunks.length
    ) {
      ZenidWebSdk.startRecording();
      setIsRecording(true);
    }
  });

  useZenidEvent("videoChunk", onVideoChunk);

  useZenidEvent("snapshot", finishRecording);

  return {
    finishRecording,
    isRecording,
  };
};

export const useFeedbackEvent = (handler: (feedback: Feedback) => void) => {
  useEvent(ZenidWebSdk.events.feedback, event => {
    pipe(
      event.detail,
      FeedbackEvent.decode,
      either.fold(
        errors => {
          console.warn(
            `Invalid feedback event provided: ${failure(errors).join("\n")}`
          );
        },
        payload => handler(payload.feedback)
      )
    );
  });
};

export const useZenidEvent = (
  event: keyof Events,
  handler: (event: unknown) => void
) => {
  useEvent(ZenidWebSdk.events[event], handler);
};

export type AcquiredDocument = {
  _tag: "Document";
  blob: Blob;
  documentCode: DocumentCode;
  pageCode: PageCode;
  hasOtherSide: boolean;
  signature: string;
};

export type AcquiredDocumentVideo = {
  _tag: "DocumentVideo";
  blob: Blob;
  documentCode: DocumentCode;
  pageCode: PageCode;
  signature: string;
};

export type AcquiredSelfie = {
  _tag: "Selfie";
  blob: Blob;
  signature: string;
};

export type AcquiredSelfieVideo = {
  _tag: "SelfieVideo";
  blob: Blob;
  signature: string;
};

export type AcquiredData =
  | AcquiredDocument
  | AcquiredDocumentVideo
  | AcquiredSelfie
  | AcquiredSelfieVideo;

export function acquiredDocument<A extends Omit<AcquiredDocument, "_tag">>(
  data: A
): AcquiredDocument {
  return { _tag: "Document", ...data };
}

export function acquiredDocumentVideo<
  A extends Omit<AcquiredDocumentVideo, "_tag">
>(data: A): AcquiredDocumentVideo {
  return { _tag: "DocumentVideo", ...data };
}

export function acquiredSelfie<A extends Omit<AcquiredSelfie, "_tag">>(
  data: A
): AcquiredSelfie {
  return { _tag: "Selfie", ...data };
}

export function acquiredSelfieVideo<
  A extends Omit<AcquiredSelfieVideo, "_tag">
>(data: A): AcquiredSelfieVideo {
  return { _tag: "SelfieVideo", ...data };
}

export function isAcquiredDocument(
  document: AcquiredData
): document is AcquiredDocument {
  return document._tag === "Document";
}

export function isAcquiredVideo(
  document: AcquiredData
): document is AcquiredDocumentVideo {
  return document._tag === "DocumentVideo";
}

export function isAcquiredSelfie(
  document: AcquiredData
): document is AcquiredSelfie {
  return document._tag === "Selfie";
}

export function isAcquiredSelfieVideo(
  document: AcquiredData
): document is AcquiredSelfieVideo {
  return document._tag === "SelfieVideo";
}
